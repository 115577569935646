/* eslint-disable */

const settings = {
  api_url: 'https://strefacukiernika.pl/api',
  video_url: 'https://strefacukiernika.pl/video',
  subscription_purchase_url: 'https://tynkowanie.strefacukiernika.pl/subskrypcja',
  subscription_price_gross: 10000,
  //subscription_price_gross: 4700,
  //subscription_price_gross_promo: 7700,
  //subscription_purchase_promo: 'Premierowa cena promocyjna ważna tylko do 02.06 do 22:00'
  //subscription_price_gross: 7700,
  //subscription_price_gross_promo: 10000,
  //subscription_purchase_promo: 'Najniższa cena z 30 dni przed obniżką 100 zł. Cena promocyjna ważna tylko do 19.01 do 22:00'
}

export default settings
